import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import '../../styles/css/ApiDocumentation.css';

class WebHooksDocumentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {this.setState({loading: false})}

    render() {
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel" style={{color: "black"}}>
                <p className="admin-header-text" style={{marginBottom:0}}>Documentation</p>
                <p className="admin-subheader-text">How to use Sqwads web hooks</p>
                <div className="section-tandem">
                    <div className="header-api">
                        Users Created Response Format:
                    </div>
                    <div className="body-api">
                        BODY:
                        <br/>
                        <ul>
                            <li>email (string)</li>
                            <li>optIn (bol)</li>
                            <li>uid (string)</li>
                            <li>projectId (string)</li>
                            <li>auth (string)</li>
                            <li>signUpTime (int, format is milliseconds from the Unix Epoch)</li>
                        </ul>
                    </div>
                </div>
                <div className="section-tandem">
                    <div className="header-api">
                        Prize Won Response Format:
                    </div>
                    <div className="body-api">
                        BODY:
                        <br/>
                        <ul>
                            <li>email (string)</li>
                            <li>uid (string)</li>
                            <li>projectId (string)</li>
                            <li>auth (string)</li>
                            <li>prize (string)</li>
                        </ul>
                    </div>
                </div>
                {/*<div className="section-tandem">*/}
                {/*    <div className="header-api">*/}
                {/*        End Form Submitted Response Format:*/}
                {/*    </div>*/}
                {/*    <div className="body-api">*/}
                {/*        BODY:*/}
                {/*        <br/>*/}
                {/*        <ul>*/}
                {/*            <li>email (string)</li>*/}
                {/*            <li>uid (string)</li>*/}
                {/*            <li>projectId (string)</li>*/}
                {/*            <li>auth (string)</li>*/}
                {/*            <li>formResponses (object)</li>*/}
                {/*            <li>otherInput (string)</li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="section-tandem">
                    <div className="header-api">
                        Verifying Webhooks
                    </div>
                    <div className="body-api">
                        For security purposes, we recommend verifying the webhooks received in your server.
                        Each webhook request includes an auth property which includes the key generated on the Webhook page in the admin.
                        The auth property can be used to verify that the requests were sent from our service.
                        <br/>
                        Additionally, if you require a range of IP addresses from which we will ping your endpoint a full list can be found <a
                        href="https://www.gstatic.com/ipranges/cloud.json" target="_blank" rel="noreferrer">here</a>.
                        Be aware, this list is large, and it can change.
                    </div>
                </div>
            </div>
         </div>
        );
    }
}

export default WebHooksDocumentation;
